<template>
    <b-card>
        <b-row>
            <!-- User Info: Left col -->
            <b-col
                cols="21"
                xl="6"
                class="d-flex justify-content-between flex-column"
            >
                <!-- User Avatar & Action Buttons -->
                <div class="d-flex justify-content-start">
                    <div style="min-width: 230px">
                        <b-button
                            v-if="
                                $route.path !== '/forms/expenses' &&
                                $route.path !== '/forms/expenses/' &&
                                userData.next_previous.previous
                            "
                            v-ripple.400="'rgba(30, 30, 30, 0)'"
                            variant="flat-secondary"
                            class="btn-icon rounded-circle mb-1 mr-right-10"
                            @click="
                                $router.push(
                                    '/forms/expenses/' +
                                        userData.next_previous.previous +
                                        '/' +
                                        userData.date.split('-')[1] +
                                        '/' +
                                        userData.date.split('-')[0]
                                )
                                $router.go()
                            "
                        >
                            <feather-icon icon="ChevronLeftIcon" size="1.5x" />
                        </b-button>
                        <b-avatar
                            :src="userData.employee.avatar"
                            size="115px"
                        />
                        <b-button
                            v-if="
                                $route.path !== '/forms/expenses' &&
                                $route.path !== '/forms/expenses/' &&
                                userData.next_previous.next
                            "
                            v-ripple.400="'rgba(30, 30, 30, 0)'"
                            variant="flat-secondary"
                            class="btn-icon rounded-circle mb-1 ml-10"
                            @click="
                                $router.push(
                                    '/forms/expenses/' +
                                        userData.next_previous.next +
                                        '/' +
                                        userData.date.split('-')[1] +
                                        '/' +
                                        userData.date.split('-')[0]
                                )
                                $router.go()
                            "
                        >
                            <feather-icon icon="ChevronRightIcon" size="1.5x" />
                        </b-button>
                    </div>
                    <div class="d-flex flex-column ml-1">
                        <div class="mb-1 mt-1">
                            <h4 class="mb-0 font-weight-bolder">
                                {{ userData.employee.name }}
                            </h4>
                            <span class="card-text">{{
                                userData.employee.job_title
                            }}</span>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col v-if="userData.employee.email" cols="12" xl="6">
                <table class="mt-2 mt-xl-0 w-100">
                    <tr>
                        <th class="pb-50">
                            <b-avatar variant="light-secondary">
                                <feather-icon icon="MailIcon" />
                            </b-avatar>
                            <span class="margin-left-10">Email</span>
                        </th>
                        <td class="pb-50">
                            {{ userData.employee.email }}
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                            <b-avatar variant="light-info">
                                <feather-icon icon="HomeIcon" />
                            </b-avatar>
                            <span class="margin-left-10">Department</span>
                        </th>
                        <td class="pb-50">
                            {{ userData.employee.department }}
                        </td>
                    </tr>
                    <tr
                        v-if="
                            userData.employee.department !== 'Rhinos' &&
                            userData.employee.department !== 'Meta'
                        "
                    >
                        <th class="pb-50">
                            <b-avatar
                                variant="light-primary"
                                :src="
                                    require('@/assets/images/icons/damiagroup.png')
                                "
                            />
                            <span class="margin-left-10">VAT number</span>
                        </th>
                        <td class="pb-50">514544848</td>
                    </tr>
                    <tr
                        v-if="
                            userData.employee.department === 'Rhinos' ||
                            userData.employee.department === 'Meta'
                        "
                    >
                        <th class="pb-50">
                            <b-avatar
                                variant="light-secondary"
                                :src="
                                    require('@/assets/images/icons/meta-logo-icon.png')
                                "
                                size="36px"
                            />
                            <span class="margin-left-10 font-weight-bold"
                                >VAT number</span
                            >
                        </th>
                        <td class="pb-50">516483765</td>
                    </tr>
                </table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {
    BCard,
    BButton,
    BAvatar,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
} from "bootstrap-vue"
import { heightFade } from "@core/directives/animations"
import Ripple from "vue-ripple-directive"

export default {
    name: "InfoExpenses",
    components: {
        BCard,
        BButton,
        BRow,
        BCol,
        BAvatar,
        BFormInput,
        BFormGroup,
    },
    directives: {
        "height-fade": heightFade,
        Ripple,
    },
    props: {
        userData: {
            type: Object,
            required: true,
        },
    },
}
</script>
