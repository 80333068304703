const setupVariant = (status) => {
    switch (status) {
        case "Open":
            return "light-info"
        case "Pending approval":
            return "light-warning"
        case "Approved":
            return "light-success"
        case "Declined":
            return "light-danger"
        case "Closed":
            return "light-dark"
        case "Pending submission":
            return "light-secondary"
        case "Pending changes":
            return "light-secondary"
        default:
            return "light-primary"
    }
}

const setupColor = (value) => {
    switch (value) {
        case "Open":
            return "info"
        case "Pending approval":
            return "warning"
        case "Approved":
            return "success"
        case "Declined":
            return "danger"
        case "Closed":
            return "dark"
        case "Pending submission":
            return "secondary"
        case "Pending changes":
            return "secondary"
        default:
            return "primary"
    }
}

export const SettingsColors = {
    setupColor,
    setupVariant,
}
