<template>
    <b-card
        class="earnings-card border-secondary"
        style="min-height: 90%; max-height: 90%"
    >
        <b-row>
            <b-col cols="6">
                <b-card-title class="mb-1"> Expenses </b-card-title>
                <div class="d-flex">
                    <div>
                        <h4 class="font-weight-bolder mb-0 text-success">
                            {{ userData.amount | currency }}
                        </h4>
                        <small class="font-small-4">Total</small>
                    </div>
                    <div style="position: absolute" class="month-mt">
                        <b-badge
                            variant="light-secondary"
                            style="font-size: 14px"
                        >
                            {{ getMonthName() }}
                        </b-badge>
                    </div>
                </div>
            </b-col>
            <b-col cols="6">
                <!-- chart -->
                <vue-apex-charts
                    height="145"
                    :options="options.chartOptions"
                    :series="series"
                    type="donut"
                />
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
} from "bootstrap-vue"
import VueApexCharts from "vue-apexcharts"
import moment from "moment"
import Ripple from "vue-ripple-directive"

export default {
    name: "LastExpenses",
    components: {
        BCard,
        BRow,
        BCol,
        BCardTitle,
        VueApexCharts,
        BDropdown,
        BDropdownItem,
        BAvatar,
        BBadge,
    },
    directives: {
        Ripple,
    },
    props: {
        userData: {
            type: Object,
            required: true,
        },
        options: {
            type: Object,
            required: true,
        },
        series: {
            type: Array,
            required: true,
        },
    },
    methods: {
        getMonthName() {
            if (this.userData.date)
                return moment(String(this.userData.date)).format("MMMM")
        },
    },
}
</script>
