<template>
    <b-table
        v-if="value.length > 0"
        ref="refExpensesTable"
        :items="value"
        :fields="generateFields()"
        caption-top
        responsive
        style="margin-bottom: 150px !important!"
        :tbody-tr-class="rowClass"
    >
        <template #cell(description)="data">
            <b-form-input
                v-if="
                    (data.item.report.status.description === 'Open' ||
                        data.item.report.status.description ===
                            'Pending changes') &&
                    expenses.is_empty === false
                "
                v-model="data.item.description"
                size="sm"
                placeholder="Enter description"
            />
            <span v-else>{{ data.item.description }}</span>
        </template>
        <template #cell(amount)="data">
            <b-input-group
                v-if="
                    (data.item.report.status.description === 'Open' ||
                        data.item.report.status.description ===
                            'Pending changes') &&
                    expenses.is_empty === false
                "
            >
                <cleave
                    id="number"
                    v-model="data.item.amount"
                    size="sm"
                    class="form-control form-control-sm"
                    :raw="false"
                    :options="{
                        numeral: true,
                        numeralThousandsGroupStyle: 'none',
                    }"
                />
                <template #append>
                    <b-input-group-text class="form-control form-control-sm"
                        >€</b-input-group-text
                    >
                </template>
            </b-input-group>
            <span v-else>{{ data.item.amount }} €</span>
        </template>
        <template #cell(category)="data">
            <b-form-select
                v-if="
                    categories.length > 0 &&
                    (data.item.report.status.description === 'Open' ||
                        data.item.report.status.description ===
                            'Pending changes') &&
                    expenses.is_empty === false
                "
                v-model="data.item.category"
                :options="categories"
                size="sm"
            />
            <span v-else-if="data.item.category">{{
                categoryName(data.item.category)
            }}</span>
        </template>
        <template #cell(invoice)="data">
            <b-row>
                <b-col v-if="!data.item.invoice" cols="12">
                    <input
                        :ref="'file-' + data.item.id"
                        type="file"
                        style="display: none"
                        @change="
                            data.item.invoice = $event.target.files[0]
                            $emit('updateExpenses', true)
                        "
                    />
                    <b-button
                        v-if="
                            (data.item.report.status.description === 'Open' ||
                                data.item.report.status.description ===
                                    'Pending changes') &&
                            expenses.is_empty === false
                        "
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="secondary"
                        size="sm"
                        @click="$refs['file-' + data.item.id].click()"
                    >
                        <feather-icon icon="UploadIcon" class="mr-50" />
                        <span class="align-middle">Upload</span>
                    </b-button>
                </b-col>
                <b-col
                    v-if="
                        data.item.invoice &&
                        typeof data.item.invoice === 'string' &&
                        (data.item.report.status.description === 'Open' ||
                            data.item.report.status.description ===
                                'Pending changes')
                    "
                    cols="12"
                >
                    <small>{{ data.item.invoice.split("/").at(-1) }}</small>
                    <feather-icon
                        v-if="
                            (data.item.report.status.description === 'Open' ||
                                data.item.report.status.description ===
                                    'Pending changes') &&
                            expenses.is_empty === false
                        "
                        v-b-tooltip.hover.top="'Remove invoice'"
                        icon="Trash2Icon"
                        class="ml-invoice text-secondary"
                        style="cursor: pointer"
                        @click="
                            data.item.invoice = ''
                            $emit('deleteExpenses', true)
                        "
                    />
                </b-col>
                <b-col
                    v-else-if="
                        data.item.report.status.description !== 'Open' &&
                        data.item.report.status.description !==
                            'Pending changes'
                    "
                >
                    <span>
                        <b-avatar variant="light-secondary">
                            <b-button
                                variant="flat-secondary"
                                class="btn-icon rounded-circle"
                                @click="openPreview(data.item.invoice)"
                            >
                                <feather-icon
                                    v-b-tooltip.hover.top="'View invoice'"
                                    icon="EyeIcon"
                                    size="16"
                                />
                            </b-button>
                        </b-avatar>
                        <b-avatar variant="light-secondary margin-left-10">
                            <b-button
                                variant="flat-secondary"
                                class="btn-icon rounded-circle"
                                @click="
                                    downloadPreview(
                                        '/' +
                                            data.item.invoice
                                                .split('/')
                                                .slice(3, 10)
                                                .join('/')
                                    )
                                "
                            >
                                <feather-icon
                                    v-b-tooltip.hover.top="'Download invoice'"
                                    icon="DownloadIcon"
                                    size="16"
                                />
                            </b-button>
                        </b-avatar>
                    </span>
                </b-col>
            </b-row>
        </template>
        <template #cell(date)="data">
            <flat-pickr
                v-if="
                    (data.item.report.status.description === 'Open' ||
                        data.item.report.status.description ===
                            'Pending changes') &&
                    expenses.is_empty === false
                "
                v-model="data.item.date"
                :config="dateMaxMin"
                class="form-control form-control-sm"
                @on-change="dateChange($event, data)"
            />
            <span v-else>{{ data.item.date }}</span>
        </template>
        <template #cell(is_real)="data">
            <span>
                <b-form-checkbox
                    :checked="data.item.is_real"
                    switch
                    :disabled="
                        !['Open', 'Pending changes'].includes(
                            data.item.report.status.description
                        )
                    "
                    @input="realValue(data.item.id)"
                />
            </span>
        </template>

        <template #cell(actions)="row">
            <span v-if="row" class="d-flex">
                <b-button
                    v-if="
                        (row.item.report.status.description === 'Open' ||
                            row.item.report.status.description ===
                                'Pending changes') &&
                        expenses.is_empty === false
                    "
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    v-b-tooltip.hover.top="'Clear'"
                    variant="flat-secondary"
                    class="btn-icon rounded-circle"
                    @click="clean(row)"
                >
                    <feather-icon icon="DeleteIcon" />
                </b-button>
                <b-button
                    v-if="
                        (row.item.report.status.description === 'Open' ||
                            row.item.report.status.description ===
                                'Pending changes') &&
                        value.length > 1 &&
                        expenses.is_empty === false
                    "
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    v-b-tooltip.hover.top="'Remove'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    @click="del(row)"
                >
                    <feather-icon icon="TrashIcon" />
                </b-button>
            </span>
        </template>
    </b-table>
</template>

<script>
import Expenses from "@/api/services/Expenses"
import {
    BTable,
    BFormInput,
    BFormFile,
    BButton,
    VBTooltip,
    BCol,
    BRow,
    BFormGroup,
    BInputGroupText,
    BInputGroup,
    BAvatar,
    BFormSelect,
    BFormCheckbox
} from "bootstrap-vue"
import flatPickr from "vue-flatpickr-component"
import vSelect from "vue-select"
import Ripple from "vue-ripple-directive"
import Cleave from "vue-cleave-component"
import Dropdown from "primevue/dropdown"
import InputNumber from "primevue/inputnumber"
import { WEEKDAYS, CATEGORY_MAP } from "@/store/constants"

export default {
    name: "ExpensesTable",
    components: {
        BTable,
        BFormInput,
        flatPickr,
        vSelect,
        BFormFile,
        BButton,
        VBTooltip,
        BRow,
        BCol,
        Cleave,
        BFormGroup,
        BInputGroupText,
        BInputGroup,
        BAvatar,
        Dropdown,
        InputNumber,
        BFormSelect,
        BFormCheckbox
    },
    directives: {
        "b-tooltip": VBTooltip,
        Ripple,
    },
    props: {
        expenses: {
            type: Object,
            require: true,
        },
        value: {
            type: Array,
            required: true,
        },
        dateMaxMin: {
            type: Object,
            default: {
                minDate: "",
                maxDate: "",
            },
        },
        action: {
            type: Boolean,
            default: true,
        },
        department: {
            type: String,
        },
    },
    data() {
        return {
            // fields: [
            //     {
            //         key: "date",
            //         class: "date-size",
            //     },
            //     {
            //         key: "day",
            //     },
            //     {
            //         key: "category",
            //         class: "category-size",
            //     },
            //     {
            //         key: "description",
            //         class: "description-size",
            //     },
            //     {
            //         key: "invoice",
            //         class: "amount-size",
            //     },
            //     {
            //         key: "amount",
            //         label: "total",
            //         class: "amount-size",
            //     },
            //     {
            //         key: "actions",
            //         label: "actions",
            //     },
            // ],
            categorySelected: "",
            categories: [],
        }
    },
    created() {
        Expenses.getCategories().then((response) => {
            const result = [
                {
                    text: "-- Please select a category --",
                    value: null,
                    disabled: true,
                },
            ]
            response.data.results.forEach((item) => {
                const ob = {}
                ob.text = item.description
                ob.value = item.id
                result.push(ob)
            })
            this.categories = result
        })
    },
    methods: {
        realValue(id) {
            this.$emit("real-value", id)
        },
        generateFields() {
            let fields = [
                {
                    key: "date",
                    class: "date-size",
                },
                {
                    key: "day",
                },
                {
                    key: "category",
                    class: "category-size",
                },
                {
                    key: "description",
                    class: "description-size",
                },
                {
                    key: "invoice",
                    class: "amount-size",
                },
                {
                    key: "amount",
                    label: "total",
                    class: "amount-size",
                }
            ]

            if (this.department !== "Rhinos") {
                fields.push({
                    key: "is_real",
                    label: "R",
                    class: "amount-size"
                })
            }
            
            fields.push({
                key: "actions",
                label: "actions",
            })

            return fields
        },
        categoryName(id) {
            return CATEGORY_MAP[id]
        },
        downloadPreview(link) {
            this.$loading(true)
            const name = link.split("/").slice(-1)
            Expenses.getFile(link).then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                )
                const link = document.createElement("a")
                link.href = url
                link.setAttribute("download", name)
                document.body.appendChild(link)
                link.click()
                setTimeout(() => {
                    this.$loading(false)
                }, 1000)
            })
        },
        openPreview(link) {
            window.open(link, "_blank")
        },
        dateChange(event, data) {
            if (event[0]) {
                const days = WEEKDAYS
                this.value[data.index].day = days[event[0].getDay()]
            }
        },
        add(item, today = false) {
            this.$loading(true)
            this.$refs.refExpensesTable.items.forEach((item) => {
                item._showDetails = false
            })
            let line = {}
            if (today) {
                const days = WEEKDAYS
                line = {
                    date: moment(new Date(), "DD-MM-YYYY").format("DD-MM-YYYY"),
                    day: days[new Date().getDay()],
                    report: item.item.report.id,
                    report_id: item.item.report.id,
                }
            } else {
                line = {
                    date: item.item.date,
                    day: item.item.day,
                    report: item.item.report.id,
                    report_id: item.item.report.id,
                }
            }
            Expenses.updateOrCreateExpensesEntry(line).then((response) => {
                Expenses.getEntry(response.data.id).then((resp) => {
                    if (today) {
                        this.value.push(resp.data)
                    } else {
                        this.value.splice(item.index + 1, 0, resp.data)
                    }
                    this.$loading(false)
                })
            })
        },
        del(item) {
            if (item.item.id) {
                Expenses.rmEntry(item.item.id)
            }
            this.value.splice(item.index, 1)
            this.$emit("delete-field", true)
        },
        clean(item) {
            item.item.category = null
            item.item.description = ""
            item.item.invoice = ""
            item.item.amount = "0.00"
        },
        rowClass(item, type) {
            return "background-table"
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.date-size {
    min-width: 170px;
    max-width: 170px;
}
.amount-size {
    min-width: 170px;
    max-width: 170px;
}
.category-size {
    min-width: 250px;
}
.description-size {
    min-width: 200px;
}
.background-table-km {
    background-color: #ebe9f1;
}
.background-table {
    background-color: #fafafc;
}
.vs__dropdown-menu {
    position: static;
    z-index: 9999999999 !important;
}
.vs__dropdown-menu {
    max-height: 100px;
}
</style>
