var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value.length > 0)?_c('b-table',{ref:"refExpensesTable",staticStyle:{"margin-bottom":"150px !important!"},attrs:{"items":_vm.value,"fields":_vm.generateFields(),"caption-top":"","responsive":"","tbody-tr-class":_vm.rowClass},scopedSlots:_vm._u([{key:"cell(description)",fn:function(data){return [(
                (data.item.report.status.description === 'Open' ||
                    data.item.report.status.description ===
                        'Pending changes') &&
                _vm.expenses.is_empty === false
            )?_c('b-form-input',{attrs:{"size":"sm","placeholder":"Enter description"},model:{value:(data.item.description),callback:function ($$v) {_vm.$set(data.item, "description", $$v)},expression:"data.item.description"}}):_c('span',[_vm._v(_vm._s(data.item.description))])]}},{key:"cell(amount)",fn:function(data){return [(
                (data.item.report.status.description === 'Open' ||
                    data.item.report.status.description ===
                        'Pending changes') &&
                _vm.expenses.is_empty === false
            )?_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',{staticClass:"form-control form-control-sm"},[_vm._v("€")])]},proxy:true}],null,true)},[_c('cleave',{staticClass:"form-control form-control-sm",attrs:{"id":"number","size":"sm","raw":false,"options":{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                }},model:{value:(data.item.amount),callback:function ($$v) {_vm.$set(data.item, "amount", $$v)},expression:"data.item.amount"}})],1):_c('span',[_vm._v(_vm._s(data.item.amount)+" €")])]}},{key:"cell(category)",fn:function(data){return [(
                _vm.categories.length > 0 &&
                (data.item.report.status.description === 'Open' ||
                    data.item.report.status.description ===
                        'Pending changes') &&
                _vm.expenses.is_empty === false
            )?_c('b-form-select',{attrs:{"options":_vm.categories,"size":"sm"},model:{value:(data.item.category),callback:function ($$v) {_vm.$set(data.item, "category", $$v)},expression:"data.item.category"}}):(data.item.category)?_c('span',[_vm._v(_vm._s(_vm.categoryName(data.item.category)))]):_vm._e()]}},{key:"cell(invoice)",fn:function(data){return [_c('b-row',[(!data.item.invoice)?_c('b-col',{attrs:{"cols":"12"}},[_c('input',{ref:'file-' + data.item.id,staticStyle:{"display":"none"},attrs:{"type":"file"},on:{"change":function($event){data.item.invoice = $event.target.files[0]
                        _vm.$emit('updateExpenses', true)}}}),(
                        (data.item.report.status.description === 'Open' ||
                            data.item.report.status.description ===
                                'Pending changes') &&
                        _vm.expenses.is_empty === false
                    )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"secondary","size":"sm"},on:{"click":function($event){_vm.$refs['file-' + data.item.id].click()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"UploadIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Upload")])],1):_vm._e()],1):_vm._e(),(
                    data.item.invoice &&
                    typeof data.item.invoice === 'string' &&
                    (data.item.report.status.description === 'Open' ||
                        data.item.report.status.description ===
                            'Pending changes')
                )?_c('b-col',{attrs:{"cols":"12"}},[_c('small',[_vm._v(_vm._s(data.item.invoice.split("/").at(-1)))]),(
                        (data.item.report.status.description === 'Open' ||
                            data.item.report.status.description ===
                                'Pending changes') &&
                        _vm.expenses.is_empty === false
                    )?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Remove invoice'),expression:"'Remove invoice'",modifiers:{"hover":true,"top":true}}],staticClass:"ml-invoice text-secondary",staticStyle:{"cursor":"pointer"},attrs:{"icon":"Trash2Icon"},on:{"click":function($event){data.item.invoice = ''
                        _vm.$emit('deleteExpenses', true)}}}):_vm._e()],1):(
                    data.item.report.status.description !== 'Open' &&
                    data.item.report.status.description !==
                        'Pending changes'
                )?_c('b-col',[_c('span',[_c('b-avatar',{attrs:{"variant":"light-secondary"}},[_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-secondary"},on:{"click":function($event){return _vm.openPreview(data.item.invoice)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('View invoice'),expression:"'View invoice'",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"EyeIcon","size":"16"}})],1)],1),_c('b-avatar',{attrs:{"variant":"light-secondary margin-left-10"}},[_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-secondary"},on:{"click":function($event){_vm.downloadPreview(
                                    '/' +
                                        data.item.invoice
                                            .split('/')
                                            .slice(3, 10)
                                            .join('/')
                                )}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Download invoice'),expression:"'Download invoice'",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"DownloadIcon","size":"16"}})],1)],1)],1)]):_vm._e()],1)]}},{key:"cell(date)",fn:function(data){return [(
                (data.item.report.status.description === 'Open' ||
                    data.item.report.status.description ===
                        'Pending changes') &&
                _vm.expenses.is_empty === false
            )?_c('flat-pickr',{staticClass:"form-control form-control-sm",attrs:{"config":_vm.dateMaxMin},on:{"on-change":function($event){return _vm.dateChange($event, data)}},model:{value:(data.item.date),callback:function ($$v) {_vm.$set(data.item, "date", $$v)},expression:"data.item.date"}}):_c('span',[_vm._v(_vm._s(data.item.date))])]}},{key:"cell(is_real)",fn:function(data){return [_c('span',[_c('b-form-checkbox',{attrs:{"checked":data.item.is_real,"switch":"","disabled":!['Open', 'Pending changes'].includes(
                        data.item.report.status.description
                    )},on:{"input":function($event){return _vm.realValue(data.item.id)}}})],1)]}},{key:"cell(actions)",fn:function(row){return [(row)?_c('span',{staticClass:"d-flex"},[(
                    (row.item.report.status.description === 'Open' ||
                        row.item.report.status.description ===
                            'Pending changes') &&
                    _vm.expenses.is_empty === false
                )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Clear'),expression:"'Clear'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-secondary"},on:{"click":function($event){return _vm.clean(row)}}},[_c('feather-icon',{attrs:{"icon":"DeleteIcon"}})],1):_vm._e(),(
                    (row.item.report.status.description === 'Open' ||
                        row.item.report.status.description ===
                            'Pending changes') &&
                    _vm.value.length > 1 &&
                    _vm.expenses.is_empty === false
                )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Remove'),expression:"'Remove'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-danger"},on:{"click":function($event){return _vm.del(row)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1):_vm._e()],1):_vm._e()]}}],null,false,929342009)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }