var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-between flex-column",attrs:{"cols":"21","xl":"6"}},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('div',{staticStyle:{"min-width":"230px"}},[(
                            _vm.$route.path !== '/forms/expenses' &&
                            _vm.$route.path !== '/forms/expenses/' &&
                            _vm.userData.next_previous.previous
                        )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(30, 30, 30, 0)'),expression:"'rgba(30, 30, 30, 0)'",modifiers:{"400":true}}],staticClass:"btn-icon rounded-circle mb-1 mr-right-10",attrs:{"variant":"flat-secondary"},on:{"click":function($event){_vm.$router.push(
                                '/forms/expenses/' +
                                    _vm.userData.next_previous.previous +
                                    '/' +
                                    _vm.userData.date.split('-')[1] +
                                    '/' +
                                    _vm.userData.date.split('-')[0]
                            )
                            _vm.$router.go()}}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"1.5x"}})],1):_vm._e(),_c('b-avatar',{attrs:{"src":_vm.userData.employee.avatar,"size":"115px"}}),(
                            _vm.$route.path !== '/forms/expenses' &&
                            _vm.$route.path !== '/forms/expenses/' &&
                            _vm.userData.next_previous.next
                        )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(30, 30, 30, 0)'),expression:"'rgba(30, 30, 30, 0)'",modifiers:{"400":true}}],staticClass:"btn-icon rounded-circle mb-1 ml-10",attrs:{"variant":"flat-secondary"},on:{"click":function($event){_vm.$router.push(
                                '/forms/expenses/' +
                                    _vm.userData.next_previous.next +
                                    '/' +
                                    _vm.userData.date.split('-')[1] +
                                    '/' +
                                    _vm.userData.date.split('-')[0]
                            )
                            _vm.$router.go()}}},[_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"1.5x"}})],1):_vm._e()],1),_c('div',{staticClass:"d-flex flex-column ml-1"},[_c('div',{staticClass:"mb-1 mt-1"},[_c('h4',{staticClass:"mb-0 font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.userData.employee.name)+" ")]),_c('span',{staticClass:"card-text"},[_vm._v(_vm._s(_vm.userData.employee.job_title))])])])])]),(_vm.userData.employee.email)?_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('table',{staticClass:"mt-2 mt-xl-0 w-100"},[_c('tr',[_c('th',{staticClass:"pb-50"},[_c('b-avatar',{attrs:{"variant":"light-secondary"}},[_c('feather-icon',{attrs:{"icon":"MailIcon"}})],1),_c('span',{staticClass:"margin-left-10"},[_vm._v("Email")])],1),_c('td',{staticClass:"pb-50"},[_vm._v(" "+_vm._s(_vm.userData.employee.email)+" ")])]),_c('tr',[_c('th',{staticClass:"pb-50"},[_c('b-avatar',{attrs:{"variant":"light-info"}},[_c('feather-icon',{attrs:{"icon":"HomeIcon"}})],1),_c('span',{staticClass:"margin-left-10"},[_vm._v("Department")])],1),_c('td',{staticClass:"pb-50"},[_vm._v(" "+_vm._s(_vm.userData.employee.department)+" ")])]),(
                        _vm.userData.employee.department !== 'Rhinos' &&
                        _vm.userData.employee.department !== 'Meta'
                    )?_c('tr',[_c('th',{staticClass:"pb-50"},[_c('b-avatar',{attrs:{"variant":"light-primary","src":require('@/assets/images/icons/damiagroup.png')}}),_c('span',{staticClass:"margin-left-10"},[_vm._v("VAT number")])],1),_c('td',{staticClass:"pb-50"},[_vm._v("514544848")])]):_vm._e(),(
                        _vm.userData.employee.department === 'Rhinos' ||
                        _vm.userData.employee.department === 'Meta'
                    )?_c('tr',[_c('th',{staticClass:"pb-50"},[_c('b-avatar',{attrs:{"variant":"light-secondary","src":require('@/assets/images/icons/meta-logo-icon.png'),"size":"36px"}}),_c('span',{staticClass:"margin-left-10 font-weight-bold"},[_vm._v("VAT number")])],1),_c('td',{staticClass:"pb-50"},[_vm._v("516483765")])]):_vm._e()])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }