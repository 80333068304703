var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[(_vm.allExpenses.length >= 1 && _vm.expenseDetail === true)?_c('b-col',{staticClass:"d-flex mb-1",staticStyle:{"cursor":"pointer"},attrs:{"cols":"12","xl":"12","lg":"12","md":"12"},on:{"click":function($event){_vm.expenseDetail = false
            _vm.expenses = {
                employee: {
                    upload_signature: '',
                },
            }}}},[_c('feather-icon',{staticStyle:{"margin-top":"3px","padding-left":"0px","margin-left":"0px"},attrs:{"icon":"ArrowLeftIcon","size":"1x"}}),_c('div',{staticStyle:{"margin-left":"5px"}},[_vm._v("Back")])],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","xl":"9","lg":"12","md":"12"}},[(
                typeof _vm.expenses === 'object' &&
                _vm.expenses.hasOwnProperty('employee') &&
                _vm.expenses.employee.hasOwnProperty('id')
            )?_c('info-expenses',{ref:"infoExpenses",attrs:{"user-data":_vm.expenses}}):_vm._e()],1),_c('b-col',{attrs:{"cols":"12","md":"12","xl":"3","lg":"12"}},[(
                _vm.resetLastExpense === true &&
                _vm.expenses.employee &&
                _vm.series.length > 0 &&
                _vm.optionsLastExpenses.chartOptions.labels.length > 0 &&
                _vm.entries.length > 0 &&
                _vm.expenses.graph &&
                _vm.up
            )?_c('last-expenses',{attrs:{"user-data":_vm.expenses,"options":_vm.optionsLastExpenses,"series":_vm.expenses.graph.series}}):_vm._e()],1),_c('b-col',[(_vm.expenses.id)?_c('b-card',[_c('div',{staticClass:"d-flex"},[(
                        _vm.prevActive &&
                        _vm.$store.state.portal.profile.employee.expense &&
                        (this.$route.path === '/forms/expenses/' ||
                            this.$route.path === '/forms/expenses')
                    )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(30, 30, 30, 0)'),expression:"'rgba(30, 30, 30, 0)'",modifiers:{"400":true}}],staticClass:"btn-icon rounded-circle mb-1",attrs:{"variant":"flat-secondary"},on:{"click":function($event){return _vm.previousMonth()}}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"1.5x"}})],1):(
                        _vm.$store.state.portal.profile.employee.expense &&
                        (this.$route.path === '/forms/expenses/' ||
                            this.$route.path === '/forms/expenses')
                    )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(30, 30, 30, 0)'),expression:"'rgba(30, 30, 30, 0)'",modifiers:{"400":true}}],staticClass:"btn-icon rounded-circle mb-1",attrs:{"variant":"flat-secondary","disabled":""},on:{"click":function($event){return _vm.previousMonth()}}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"1.5x"}})],1):_vm._e(),(
                        _vm.nextActive &&
                        _vm.$store.state.portal.profile.employee.expense &&
                        (this.$route.path === '/forms/expenses/' ||
                            this.$route.path === '/forms/expenses')
                    )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(30, 30, 30, 0)'),expression:"'rgba(30, 30, 30, 0)'",modifiers:{"400":true}}],staticClass:"btn-icon rounded-circle mb-1",attrs:{"variant":"flat-secondary"},on:{"click":function($event){return _vm.nextMonth()}}},[_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"1.5x"}})],1):(
                        _vm.$store.state.portal.profile.employee.expense &&
                        (this.$route.path === '/forms/expenses/' ||
                            this.$route.path === '/forms/expenses')
                    )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(30, 30, 30, 0)'),expression:"'rgba(30, 30, 30, 0)'",modifiers:{"400":true}}],staticClass:"btn-icon rounded-circle mb-1",attrs:{"variant":"flat-secondary","disabled":""},on:{"click":function($event){return _vm.nextMonth()}}},[_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"1.5x"}})],1):_vm._e(),(
                        _vm.$store.state.portal.profile.employee.expense &&
                        (this.$route.path === '/forms/expenses/' ||
                            this.$route.path === '/forms/expenses')
                    )?_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(30, 30, 30, 0)'),expression:"'rgba(30, 30, 30, 0)'",modifiers:{"400":true}}],staticClass:"btn-icon mb-1 ml-10",staticStyle:{"margin-top":"-5px"},attrs:{"id":"monthDate","size":"lg","variant":"flat-secondary"},on:{"click":function($event){_vm.monthPicker =
                                _vm.monthPicker === true ? false : true}}},[_vm._v(" "+_vm._s(_vm._f("monthYearFormat")(_vm.expenses.date))+" "),_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"1x"}})],1),(_vm.monthPicker && _vm.expenses.date)?_c('div',{staticClass:"mp-index",staticStyle:{"position":"absolute"}},[(_vm.monthPicker && _vm.expenses.date)?_c('Calendar',{attrs:{"view":"month","date-format":"mm/yy","inline":true,"min-date":new Date(
                                    new Date(_vm.expenses.months).setMonth(
                                        new Date(_vm.expenses.months).getMonth()
                                    )
                                ),"max-date":new Date(
                                    new Date().setMonth(
                                        new Date().getMonth() + 1
                                    )
                                )},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}}):_vm._e(),_c('div',{staticClass:"d-flex custom-month-picker",staticStyle:{"flex-direction":"row-reverse"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"margin-left-10",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.showDate(_vm.month)}}},[_vm._v(" Apply ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary","size":"sm"},on:{"click":function($event){_vm.monthPicker =
                                        _vm.monthPicker === true ? false : true}}},[_vm._v(" Cancel ")])],1)],1):_vm._e()],1):_vm._e(),(
                        !_vm.$store.state.portal.profile.employee.expense ||
                        (this.$route.path !== '/forms/expenses/' &&
                            this.$route.path !== '/forms/expenses')
                    )?_c('div',{staticClass:"ml-1 mt-10 fs-16 mb-1"},[_c('span',{staticStyle:{"font-size":"1.25rem","font-weight":"500","color":"#82868b"}},[_vm._v(_vm._s(_vm._f("monthYearFormat")(_vm.expenses.date)))])]):_vm._e(),_c('div',{staticClass:"mr-auto mt-10"},[(
                            _vm.expenses.status &&
                            Object.keys(_vm.expenses.status).length !== 0
                        )?_c('b-badge',{staticClass:"ml-1",attrs:{"variant":'light-' + _vm.setStatusColor()}},[_vm._v(" "+_vm._s(_vm.expenses.status.description)+" ")]):_vm._e()],1),_c('div',[(
                            _vm.$route.path !== '/forms/expenses' &&
                            _vm.$route.path !== '/forms/expenses/'
                        )?_c('b-dropdown',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0)'),expression:"'rgba(255, 255, 255, 0)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"id":"dropdown-7","text":"Actions","variant":"secondary"}},[_c('b-dropdown-item',[_vm._v("Select action:")]),_c('b-dropdown-item',{attrs:{"disabled":!_vm.approve},on:{"click":function($event){return _vm.save(3)}}},[_c('feather-icon',{staticClass:"text-success mr-right-10",attrs:{"icon":"CheckIcon"}}),_vm._v("Approve")],1),_c('b-dropdown-item',{attrs:{"disabled":!_vm.decline},on:{"click":function($event){return _vm.save(4)}}},[_c('feather-icon',{staticClass:"text-danger mr-right-10",attrs:{"icon":"XIcon"}}),_vm._v("Decline")],1),_c('b-dropdown-item',{attrs:{"disabled":!_vm.nudge},on:{"click":function($event){return _vm.nudgeAction()}}},[_c('feather-icon',{staticClass:"text-warning mr-right-10",attrs:{"icon":"SendIcon"}}),_vm._v("Nudge")],1),_c('b-dropdown-item',{attrs:{"disabled":!_vm.reopen},on:{"click":function($event){return _vm.save(1)}}},[_c('feather-icon',{staticClass:"text-info mr-right-10",attrs:{"icon":"RotateCwIcon"}}),_vm._v("Reopen")],1),_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal.change-request",modifiers:{"change-request":true}}],attrs:{"disabled":!_vm.changeRequest}},[_c('feather-icon',{staticClass:"text-secondary mr-right-10",attrs:{"icon":"Edit3Icon"}}),_vm._v("Change request")],1)],1):_vm._e(),_c('b-dropdown',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"flat-secondary","text":"Export","disabled":_vm.expenses.status.description === 'Closed' &&
                            _vm.expenses.is_empty === true},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{attrs:{"icon":"DownloadIcon","size":"1.5x"}})]},proxy:true}],null,false,2902842924)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.download()}}},[_vm._v(" Excel ")]),_c('b-dropdown-item',{on:{"click":function($event){_vm.download((_vm.exportTo = 'pdf'))}}},[_vm._v(" PDF ")])],1)],1)],1),_c('b-modal',{attrs:{"id":"change-request","centered":"","title":"Change request","cancel":"","cancel-variant":"outline-secondary","ok-title":"Submit request","size":"lg"},on:{"ok":function($event){return _vm.submitRequest()}}},[_c('b-card-text',[_vm._v(" Incorrect or missing information? Submit a change request to the employee. ")]),_c('b-card-text',[_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Requested by","label-for":"requestedBy"}},[_c('b-form-input',{attrs:{"id":"requestedBy","disabled":"","value":_vm.$store.state.portal.profile.first_name +
                                ' ' +
                                _vm.$store.state.portal.profile.last_name}})],1)],1),_c('b-card-text',[_c('label',{attrs:{"for":"reason-for-change"}},[_vm._v("Comments")]),_c('b-form-textarea',{attrs:{"id":"reason-for-change","placeholder":"Please use this space to explain reason for change, as well as additional information.","rows":"3"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)],1),_c('div',[(_vm.request)?_c('app-collapse',{staticClass:"shadow mb-1 bg-white rounded",staticStyle:{"padding-bottom":"10px","z-index":"1","overflow":"scroll"}},[_c('app-collapse-item',{ref:"tlMessages",staticStyle:{"z-index":"1"},attrs:{"is-visible":true,"title":_vm.isVisible
                                ? ' ' + 'Hide change request'
                                : ' ' + 'View change request'},on:{"visible":function($event){_vm.isVisible = $event}}},[_c('app-timeline',{staticStyle:{"z-index":"1"}},_vm._l((_vm.messages),function(item){return _c('app-timeline-item',{key:item.id,staticClass:"mt-1",staticStyle:{"z-index":"1"},attrs:{"title":item.created_by.first_name +
                                    ' ' +
                                    item.created_by.last_name +
                                    ':',"subtitle":item.content,"time":item.created_at,"variant":"primary"}})}),1)],1)],1):_vm._e()],1),(
                    _vm.entries.length > 0 &&
                    _vm.dateMaxMin.minDate !== '' &&
                    _vm.rerendereExpensesTable
                )?_c('expenses-table',{ref:"refExpensesTable",attrs:{"value":_vm.entries,"date-max-min":_vm.dateMaxMin,"action":_vm.action,"expenses":_vm.expenses},on:{"real-value":function($event){return _vm.getRValue($event)},"updateExpenses":function($event){return _vm.updateExpenses()},"deleteExpenses":_vm.updateExpenses,"delete-field":_vm.deleteField}}):_vm._e(),(
                    (_vm.expenses.status.description === 'Open' ||
                        _vm.expenses.status.description === 'Closed') &&
                    _vm.entries.length <= 1
                )?_c('b-card-text',{staticClass:"text-primary ml-1 font-weight-bolder"},[_vm._v(" If you don't have expense to declare this month, please select the following option and then click on \"Submit\": "),_c('b-form-checkbox',{staticClass:"mt-10 mb-1",attrs:{"disabled":_vm.expenses.status.description === 'Closed' ||
                        _vm.expenses.condition},model:{value:(_vm.expenses.is_empty),callback:function ($$v) {_vm.$set(_vm.expenses, "is_empty", $$v)},expression:"expenses.is_empty"}},[_vm._v(" I confirm that I have no expense to declare for the current cycle. ")])],1):_vm._e(),(_vm.expenses.is_empty === false)?_c('b-card-text',{staticClass:"ml-1 font-weight-bolder",staticStyle:{"font-size":"12px","color":"#5e5873"}},[_vm._v(" I accept"),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(": "),_c('b-form-checkbox',{staticClass:"mt-10 mb-2",attrs:{"disabled":_vm.expenses.status.description !== 'Open' ||
                        _vm.expenses.is_empty},model:{value:(_vm.expenses.condition),callback:function ($$v) {_vm.$set(_vm.expenses, "condition", $$v)},expression:"expenses.condition"}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Employee Portal uses your signature exclusively for the purpose of signing expense reports, timesheets and mileage reports, if applicable.")])])],1):_vm._e(),(
                    _vm.expenses.status &&
                    _vm.expenses.is_empty === false &&
                    (_vm.expenses.status.description === 'Open' ||
                        _vm.expenses.status.description === 'Pending changes')
                )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(0, 0,	0, 0)'),expression:"'rgba(0, 0,\t0, 0)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.add(
                        {
                            item: _vm.entries[0],
                        },
                        true
                    )}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Add expense")])],1):_vm._e(),_c('div',{staticStyle:{"float":"right"}},[_c('div',{staticClass:"demo-inline-spacing"},[(
                            _vm.expenses.status &&
                            _vm.expenses.is_empty === false &&
                            (_vm.expenses.status.description === 'Open' ||
                                _vm.expenses.status.description ===
                                    'Pending changes')
                        )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(0, 0,	0, 0)'),expression:"'rgba(0, 0,\t0, 0)'",modifiers:{"400":true}}],attrs:{"variant":"flat-primary"},on:{"click":function($event){return _vm.saveExpenses()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Save")])],1):_vm._e(),(
                            _vm.expenses.status &&
                            (_vm.expenses.status.description === 'Open' ||
                                _vm.expenses.status.description ===
                                    'Pending changes')
                        )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(0, 0,	0, 0)'),expression:"'rgba(0, 0,\t0, 0)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Submit ")]):_vm._e(),_c('b-modal',{attrs:{"id":"model-signature","centered":"","title":"Signature","ok-only":"","ok-title":"Save","size":"lg"},on:{"ok":_vm.signatureSave}},[_c('b-card-text',[_c('h5',[_vm._v(" Sign your expense form before submitting it for approval. Please select one of these options: ")]),_c('b-tabs',{staticClass:"mt-1",attrs:{"content-class":"mt-2","justified":""},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',{staticStyle:{"min-width":"100%"},attrs:{"active":"","title":"Create"}},[_c('b-card-text',[_c('h4',[_vm._v("Create digital signature")]),_c('p',[_vm._v(" When creating a digital signature your expense forms will be automatically signed monthly. ")]),_c('p',[_vm._v(" It's easy, fast and eliminates paper waste! ")]),_vm._v(" Use your mouse to draw the signature. Click “Save” when you make a perfect one. ")]),_c('vueSignature',{ref:"signature",staticStyle:{"border":"1px solid black"},attrs:{"sig-option":_vm.option,"w":"760px","h":"300px","disabled":_vm.disabled}}),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:(
                                            'rgba(186, 191, 199, 0.15)'
                                        ),expression:"\n                                            'rgba(186, 191, 199, 0.15)'\n                                        ",modifiers:{"400":true}}],staticClass:"mt-1",staticStyle:{"float":"right"},attrs:{"variant":"flat-secondary"},on:{"click":_vm.clear}},[_c('feather-icon',{staticClass:"mr-right-10",attrs:{"icon":"RotateCwIcon"}}),_vm._v(" Clear ")],1)],1),_c('b-tab',{attrs:{"title":"Upload"}},[_c('b-card-text',[_c('h4',[_vm._v("Upload a signature")]),_c('p',[_vm._v(" If you already have an image of your signature, you can also upload it and use it. ")]),_c('p',[_vm._v(" You no longer have to worry about signing expense form every time you submit it for approval. ")]),_c('p',[_vm._v("Once and never again!")])]),(_vm.expenses.employee)?_c('b-form-file',{staticClass:"mt-1",attrs:{"id":"input-upload-signature","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.upload_signature),callback:function ($$v) {_vm.upload_signature=$$v},expression:"upload_signature"}}):_vm._e(),(
                                            _vm.$store.state.portal.profile
                                                .employee !== 'undefined' &&
                                            _vm.$store.state.portal.profile
                                                .employee.upload_signature
                                        )?_c('p',{staticStyle:{"font-size":"10px"}},[_vm._v(" Selected file: "),_c('strong',[_c('a',{attrs:{"href":_vm.$store.state.portal
                                                        .profile.employee
                                                        .upload_signature
                                                        ? _vm.$store.state
                                                              .portal
                                                              .profile
                                                              .employee
                                                              .upload_signature
                                                        : '',"target":"_blank"}},[_vm._v("My Signature")])])]):_vm._e()],1),_c('b-tab',{attrs:{"title":"Manual"}},[_c('b-card-text',[_c('h4',[_vm._v("Manual")]),_c('p',[_vm._v(" You can also sign expense form manually. ")]),_c('p',[_vm._v(" To do this, make sure you have completed all missing entries, download your expense file, sign it, scan it and finally send it back by clicking “Upload it”. ")])]),_c('div',{staticStyle:{"text-align":"center"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:(
                                                'rgba(24,	91,	137, 0.15)'
                                            ),expression:"\n                                                'rgba(24,\t91,\t137, 0.15)'\n                                            ",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"},on:{"click":function($event){_vm.download((_vm.exportTo = 'pdf'))}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"DownloadIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Download")])],1)],1),_c('b-card-text',{staticClass:"mt-1"},[_c('p',[_vm._v(" Already signed? Upload it below: ")])]),_c('b-form-file',{attrs:{"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('p',{staticStyle:{"font-size":"10px"}},[_vm._v(" Selected file: "),_c('strong',[_vm._v(_vm._s(_vm.file ? _vm.file.name : ""))])]),_c('b-card-text',{staticClass:"text-warning mt-1"},[_vm._v(" Keep in mind that by choosing this option, you must to repeat this signing process every time you submit a expense form. ")])],1)],1)],1)],1),(
                            _vm.expenses.status &&
                            (_vm.expenses.status.description === 'Open' ||
                                _vm.expenses.status.description ===
                                    'Pending changes') &&
                            !_vm.$store.state.portal.profile.employee
                                .signature &&
                            !_vm.$store.state.portal.profile.employee
                                .upload_signature
                        )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(24,	91,	137, 0.15)'),expression:"'rgba(24,\t91,\t137, 0.15)'",modifiers:{"400":true}},{name:"b-modal",rawName:"v-b-modal.model-signature",modifiers:{"model-signature":true}}],attrs:{"variant":"outline-primary"}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit3Icon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Signature")])],1):_vm._e()],1)])],1):(_vm.notExpenses)?_c('div'):_c('b-card',[_c('div',{staticClass:"custom-search d-flex"},[_c('div',{staticClass:"d-flex mr-auto"},[_c('b-card-text',{staticStyle:{"font-size":"16px"}},[_vm._v(" Click on date to check or record your expense ")])],1),_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-group',[_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)],1)]),(_vm.resetLastExpense)?_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.allExpenses,"search-options":{
                    enabled: true,
                    externalQuery: _vm.searchTerm,
                }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'status')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.status.description)}},[_vm._v(" "+_vm._s(props.row.status.description)+" ")])],1):(props.column.field === 'employee.name')?_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.$router.push(
                                '/forms/expenses/' +
                                    props.row.employee.id +
                                    '/' +
                                    props.row.date.split('-')[1] +
                                    '/' +
                                    props.row.date.split('-')[0]
                            )}}},[_c('b-avatar',{staticClass:"mr-right-10",attrs:{"size":"36px","src":props.row.employee.avatar}}),_vm._v(" "+_vm._s(props.row.employee.name)+" ")],1):(
                            props.column.field ===
                            'employee.reports_to.name'
                        )?_c('span',{staticClass:"d-flex"},[(props.row.employee.reports_to)?_c('b-avatar',{staticClass:"mr-right-10",attrs:{"size":"36px","src":props.row.employee.reports_to.avatar}}):_vm._e(),(props.row.employee.reports_to)?_c('div',{staticClass:"name-vt-align"},[_vm._v(" "+_vm._s(props.row.employee.reports_to.name)+" ")]):_vm._e()],1):(props.column.field === 'date')?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
                            'Click to check or record!'
                        ),expression:"\n                            'Click to check or record!'\n                        ",modifiers:{"hover":true,"right":true}}],staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.showDetail(
                                props.formattedRow[props.column.field]
                            )}}},[_vm._v(" "+_vm._s(_vm._f("monthYearFormat")(props.formattedRow[props.column.field]))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,2203975873)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }