import Api from "@/api"
import * as Constants from "@/store/constants"

const EXPENSES = "/forms/expenses/"
const EXPENSESENTRIES = "/forms/entries/expenses/"
const EXPENSESENTRIESEXPORT = "/forms/export/expenses/"
const PEOPLESEXPENSES = "/forms/peoples/expenses/"
const PEOPLESEXPENSE = "/forms/peoples/expense/"
const CATEGORIES = "/forms/categories/expenses/"
const NUDGE = "/expenses/nudge/"
const MESSAGE = "expenses/message/"
const EXPENSEMAXMIN = "/forms/peoples/expenses/maxmin/"
const EXPENSEREPORT = "/reports/expense/"
const EXPENSEREPORTITEMS = "/reports/items/expense/"

export default {
    getExpense(id) {
        return Api.https(true).get(`${EXPENSES + id}/`)
    },
    getExpenses(year, month, id = "") {
        if (id !== "") {
            return Api.https(true).get(
                `${EXPENSES}?date__year=${year}&date__month=${month}&employee_id=${id}`
            )
        }
        return Api.https(true).get(
            `${EXPENSES}?date__year=${year}&date__month=${month}`
        )
    },
    putExpenses(data) {
        return Api.https(true).put(`${EXPENSES + data.id}/`, data)
    },
    getExpenseEntries(id) {
        return Api.https(true).get(`${EXPENSESENTRIES}?report_id=${id}`)
    },
    getPeoplesExpenses(year, month) {
        return Api.https(true).get(
            `${PEOPLESEXPENSES}?date__year=${year}&date__month=${month}`
        )
    },
    getPeoplesExpense(year, month) {
        return Api.https(true, `${Constants.API_BASEURL}v2`).get(
            `${PEOPLESEXPENSE}?date__year=${year}&date__month=${month}`
        )
    },
    getCategories() {
        return Api.https(true).get(CATEGORIES)
    },
    updateOrCreatePeoplesExpenses(data) {
        if ("id" in data) {
            if ("attachment" in data) {
                const formData = new FormData()
                for (const [key, value] of Object.entries(data)) {
                    formData.append(key, value)
                }
                return Api.https(true, `${Constants.API_BASEURL}v2`).put(
                    `${PEOPLESEXPENSE + data.id}/`,
                    formData
                )
            }
            return Api.https(true, `${Constants.API_BASEURL}v2`).put(
                `${PEOPLESEXPENSE + data.id}/`,
                data
            )
        }
        return Api.https(true, `${Constants.API_BASEURL}v2`).post(
            PEOPLESEXPENSE,
            data
        )
    },
    deletePeoplesExpenses(id) {
        return Api.https(true, `${Constants.API_BASEURL}v2`).delete(
            `${PEOPLESEXPENSE + id}/`
        )
    },
    downloadEntries(id, type = "excel") {
        if (type === "excel") {
            return Api.https(true).get(`${EXPENSESENTRIESEXPORT + id}/`, {
                responseType: "blob",
            })
        }
        return Api.https(true).get(
            `${EXPENSESENTRIESEXPORT + id}/?export=pdf`,
            {
                responseType: "blob",
            }
        )
    },
    postNudge(data) {
        return Api.https(true).post(NUDGE, data)
    },
    getMessage(id) {
        return Api.https(true).get(`${MESSAGE}?expense_id=${id}`)
    },
    postMessage(data) {
        return Api.https(true).post(MESSAGE, data)
    },
    getMinMaxDate() {
        return Api.https(true).get(EXPENSEMAXMIN)
    },
    rmEntry(id) {
        return Api.https(true).delete(`${EXPENSESENTRIES + id}/`)
    },
    async updateOrCreateExpensesEntry(data) {
        let valid = false
        if ("invoice" in data && typeof data.invoice === "object") {
            const formData = new FormData()
            for (const [key, value] of Object.entries(data)) {
                if (key == "id") {
                    valid = value
                }
                formData.append(key, value)
            }
            return Api.https(true).put(`${EXPENSESENTRIES + valid}/`, formData)
        }
        if ("invoice" in data && data.invoice === "") {
            data.invoice = null
        } else {
            delete data.invoice
        }
        if ("id" in data) {
            return Api.https(true).put(`${EXPENSESENTRIES + data.id}/`, data)
        }
        return Api.https(true).post(EXPENSESENTRIES, data)
    },
    getEntry(id) {
        return Api.https(true).get(`${EXPENSESENTRIES + id}/`)
    },
    getExpenseReport() {
        return Api.https(true).get(EXPENSEREPORT)
    },
    getExpenseReportItems(date) {
        return Api.https(true).get(`${EXPENSEREPORTITEMS}?report__date=${date}`)
    },
    myExpenses(id) {
        return Api.https(true).get(`${PEOPLESEXPENSES}?my=1&employee_id=${id}`)
    },
    getFile(link) {
        return Api.https(true, Constants.URL).get(link, {
            responseType: "blob",
        })
    },
}
